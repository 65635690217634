import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useScroll } from '~/hooks'
import { useApi } from '~/siteApi'
import {
  Head,
  Hero,
  EnterpriseSlider,
  EnterpriseListing,
  Footer,
} from '~/components'
import Banner from '~/assets/img/Banner-Empreendimentos.jpg'

const Home = ({ location }: PageProps) => {
  useScroll()

  const { constructions: enterprises }: { constructions: Array<Enterprise> } =
    useApi(
      {
        constructions: useStaticQuery(graphql`
          query {
            allEnterprises {
              nodes {
                _id
                cover
                image
                slug
                name
                address
                district
                city
                state {
                  name
                  acronym
                }
                cep
                description
                category {
                  name
                }
                completePlan
                photos {
                  legend
                  miniature
                  zoom
                }
              }
            }
          }
        `).allEnterprises.nodes,
      },
      'constructions'
    )

  const releases = enterprises.filter((i) => i.category?.name === 'Lançamento')
  const underConstruction = enterprises.filter(
    (i) => i.category?.name === 'Construção'
  )
  const delivered = enterprises.filter((i) => i.category?.name === 'Entregue')

  return (
    <>
      <Head
        location={location}
        title={`Empreendimentos - ${process.env.GATSBY_SITE_NAME}`}
      />
      <Hero
        title="Empreendimentos"
        description={
          'Aliando inovação construtiva\ne tecnologia, a Holder busca oferecer\numa experiência única aos clientes.'
        }
        image={Banner}
      />
      {Boolean(releases.length) && (
        <EnterpriseSlider
          title="Lançamentos"
          id="Lancamentos"
          enterprises={releases}
          style={
            underConstruction.length || delivered.length
              ? { paddingBottom: 10 }
              : null
          }
        />
      )}
      {Boolean(underConstruction.length) && (
        <EnterpriseSlider
          title="Em construção"
          id="EmConstrucao"
          enterprises={underConstruction}
          style={
            releases.length || delivered.length
              ? {
                  ...(releases.length ? { marginTop: -10 } : {}),
                  ...(delivered.length ? { paddingBottom: 10 } : {}),
                }
              : null
          }
        />
      )}
      {Boolean(delivered.length) && (
        <EnterpriseListing
          title="Entregues"
          id="Entregues"
          enterprises={delivered}
          style={
            releases.length || underConstruction.length
              ? { marginTop: -10 }
              : null
          }
        />
      )}
      <Footer />
    </>
  )
}

export default Home
